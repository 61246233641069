// eslint-disable
import React, { useState } from "react"
// import React, { useState, useEffect } from "react"
import axios from "axios"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import SEO from "../../../components/seo"

import Preview from "../../../components/preview"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faTimes,
  faChevronLeft,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons"

import TwoColumn from "../../../components/two-column"
import WorkEmailAddress from "../../../components/work-email-address"
import AccountManagement from "../../../components/account-management"

const BrandIdentity = () => {
  const MAILERLITE_GROUP_ID = "69891226 "
  const MAILERLITE_API_KEY = "f13b6f484a31a41bbdefdd972b1d6099"
  const RESKPACK_API_KEY = "geu3Icyx9aEArMy1tEbWj0aVDu97tXLdCYFJRBkLuFiULbjS"
  const BRANDFETCH_API_KEY = "oIZ9PT7H129HV3PWel5w93cTdvdE1QYI63811Aa4"
  const GCMS_TOKEN =
    "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE1ODk4OTI0MjEsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEuZ3JhcGhjbXMuY29tL3YyL2NrOXk4a3d1YjA3angwMXo0OG53bWV4amQvbWFzdGVyIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMmY5ZjU3YzMtYWMyZi00ZGQzLWI5YTItOTdhODdlZjE4ZmFlIiwianRpIjoiY2thZHd3YnFsMDYydTAxd20yaTE4OWR6ciJ9.o79Ip3HHiuaxTLYbHPJsWtIi52w-nMVP5iv7xyGHDm0jJ-OHOB9bJbP-zj22h7XtrMiXwXLo9o6Br2O10gaHefYM9l14le8YMxkCFOc8xv8b7d6mbUY6Hh0OIyO62DQysETve9P_uQn8PIe8QS7vwivfBcs9WFQR--6HMN2XmWDTPWKp9_whRbBqQ0uVcJ83R4uqh_ivT9IjzFHbNKxAHFL7LzV_EQ96aROzMhcgOBVs7-w_EAtP1VK0s5Jmus70-AOJktEPWppICNLwX3J8I7IqY_YzTD1OcWcgqOvSPdMOS1uSgsroKJDdF6vdYWYiBkx0kaQmSQ6Q1XTnuwxd9Bo8UEKgTaQ8RG_gPMTcX6nR-q5_7uITi9T5WGK542yOJ0AC7BmtUYNdOSAqytC6ILbu10Oq5oQPzm_XPdJ_gLg7Vc55-Obq_oWPzAo5-L5aV7gJC8Onr8I-6DxxEyeXbW-oTlKrHBecebWXyMl681cn8C8YlEwbgleW8UbujOVD8xFfe2q7nhcumEaFf7SYnFc4ljmwDnljvR_5DfJgU9grg5qPfUFcALp4_CDF_g_GAjNaf8l9iT0BK0n_gzu0Jyb04nzayXH--CGvEuwGdpf2U1R2nBWIp7NoR0Alu-ctIGGr7j-qWwIA04VUdEleIcKQPGlswSRtLrtbJl9MpI0"
  const GCLOUD_BASE_URL = "https://storage.googleapis.com/"
  const GCLOUD_BUCKET_NAME = "billsby-marketing-site-assets"

  const [email, hasEmail] = useState("")
  const [screenshot, hasScreenShot] = useState("")
  const [logo, hasLogo] = useState("")
  const [colorLight, hasColorLight] = useState("")
  const [colorDark, hasColorDark] = useState("")
  const [name, hasName] = useState("")
  const [banner, hasBanner] = useState("")

  const [btnWand, setBtnWand] = useState(false)
  const [disabled, isDisabled] = useState(true)

  const [errorState, isErrorState] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [success, isSuccess] = useState(false)

  const handleChange = event => {
    hasEmail(event.target.value)
    isDisabled(false)
  }

  async function doWork() {
    //CHECK IF EMAIL IS VALID
    const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const result = pattern.test(email)

    if (result === true) {
      var split = email.split("@")
      var domain = split[1]

      setBtnWand(true)
      isDisabled(true)

      // METHOD TO CHECK IF DOMAIN EXIST IN GRAPHCMS
      let resCheck = await axios({
        method: "POST",
        url:
          "https://api-us-east-1.graphcms.com/v2/ck9y8kwub07jx01z48nwmexjd/master",
        headers: {
          "Content-Type": "application/json",
          Authorization: GCMS_TOKEN,
        },
        data: {
          query: `
                    query checkBrands() {
                        companyCustomizations(stage: PUBLISHED) {
                            id
                            domain
                            name
                        }
                    }
                    `,
        },
      })

      const resList = resCheck.data.data.companyCustomizations
      function domainExists(domain) {
        return resList.some(function(el) {
          return el.domain === domain
        })
      }
      const isExist = domainExists(domain)

      if (!isExist) {
        setBtnWand(true)
        isDisabled(true)

        try {
          //GET SCREENSHOT
          let resSS = await axios({
            method: "POST",
            url:
              "https://cors-anywhere.herokuapp.com/https://restpack.io/api/screenshot/v6/capture",
            headers: {
              "content-type": "application/json",
              "x-access-token": RESKPACK_API_KEY,
            },
            json: true,
            data: {
              url: `https://${domain}`,
              mode: "viewport",
              width: "1920",
              height: "950",
              json: true,
              format: "png",
              filename: `${domain}.png`,
            },
          })

          //PASSING IMAGE TO BILLSBY END POINT THE SAVING TO GCLOUD
          const ss = resSS.data.image
          let saveAndConvertSS = await axios({
            method: "POST",
            url: "https://billsby-mailgun.onrender.com/api/guploader",
            data: {
              name: domain,
              url: ss,
            },
          })

          //GET LOGO
          let resLogo = await axios({
            method: "POST",
            url:
              "https://cors-anywhere.herokuapp.com/https://api.brandfetch.io/v1/logo",
            headers: {
              "x-api-key": BRANDFETCH_API_KEY,
              "content-type": "application/json",
            },
            data: {
              domain: domain,
            },
          })

          //PASSING IMAGE TO BILLSBY END POINT THE SAVING TO GCLOUD
          const logo = resLogo.data.response.logo.image
          let saveAndConvertLogo = await axios({
            method: "POST",
            url: "https://billsby-mailgun.onrender.com/api/guploader",
            data: {
              name: `${domain}-logo`,
              url: logo,
            },
          })

          //GET COLOR
          let resColor = await axios({
            method: "POST",
            url:
              "https://cors-anywhere.herokuapp.com/https://api.brandfetch.io/v1/color",
            headers: {
              "x-api-key": BRANDFETCH_API_KEY,
              "content-type": "application/json",
            },
            data: {
              domain: domain,
            },
          })

          //GET COMPANY NAME AND BANNER
          let resCompany = await axios({
            method: "POST",
            url:
              "https://cors-anywhere.herokuapp.com/https://api.brandfetch.io/v1/company",
            headers: {
              "x-api-key": BRANDFETCH_API_KEY,
              "content-type": "application/json",
            },
            data: {
              domain: domain,
            },
          })

          //PASSING IMAGE TO BILLSBY END POINT THE SAVING TO GCLOUD - CHECK BANNER IS TRUE
          const banner = resCompany.data.response.banner
          let saveAndConvertBanner
          if (banner) {
            saveAndConvertBanner = await axios({
              method: "POST",
              url: "https://billsby-mailgun.onrender.com/api/guploader",
              data: {
                name: `${domain}-banner`,
                url: banner,
              },
            })
          }

          // SAVE TO GRAPHCMS
          let graph = await axios({
            method: "POST",
            url:
              "https://api-us-east-1.graphcms.com/v2/ck9y8kwub07jx01z48nwmexjd/master",
            headers: {
              "Content-Type": "application/json",
              Authorization: GCMS_TOKEN,
            },
            data: {
              query: `
                            mutation CreateCompanyCustomization($domain: String!, $name: String!, $darkColor: String!, $lightColor: String!, $logoUrl: String!, $bannerUrl: String!, $screenShot: String! ) {
                                    createCompanyCustomization(
                                        data: {
                                            domain: $domain
                                            name: $name
                                            darkColor: $darkColor
                                            lightColor: $lightColor
                                            logoUrl: $logoUrl
                                            bannerUrl: $bannerUrl
                                            screenShot: $screenShot
                                        }
                                    ) {
                                        id
                                        domain
                                        name
                                        darkColor
                                        lightColor
                                        logoUrl
                                        bannerUrl
                                        screenShot
                                    }
                                }
                            `,
              variables: {
                domain: `${domain}`,
                name: `${resCompany.data.response.name}`,
                screenShot: `${GCLOUD_BASE_URL}${GCLOUD_BUCKET_NAME}/${saveAndConvertSS.data.name}`,
                darkColor: `${resColor.data.response.filtered.dark}`,
                lightColor: `${
                  resColor.data.response.filtered.vibrant
                    ? resColor.data.response.filtered.vibrant
                    : resColor.data.response.filtered.dark
                }`,
                logoUrl: `${GCLOUD_BASE_URL}${GCLOUD_BUCKET_NAME}/${saveAndConvertLogo.data.name}`,
                bannerUrl: `${
                  resCompany.data.response.banner
                    ? GCLOUD_BASE_URL +
                      GCLOUD_BUCKET_NAME +
                      "/" +
                      saveAndConvertBanner.data.name
                    : "No available Banner"
                }`,
              },
            },
          }).then(res => {
            //PUBLISH DATA AFTER SAVING
            axios({
              method: "POST",
              url:
                "https://api-us-east-1.graphcms.com/v2/ck9y8kwub07jx01z48nwmexjd/master",
              headers: {
                "Content-Type": "application/json",
                Authorization: GCMS_TOKEN,
              },
              data: {
                query: `
                                mutation publishCompanyCustomization() {
                                    publishCompanyCustomization(where: {id: "${res.data.data.createCompanyCustomization.id}"}, to: PUBLISHED) {
                                    documentInStages(includeCurrent: false) {
                                        id
                                    }
                                    }
                                }                  
                                `,
              },
            })
          })

          //SET ALL THE STATE VALUES WITH NULL FALLBACK
          setBtnWand(false)
          hasScreenShot(resSS.data.image)
          hasLogo(resLogo.data.response.logo.image)
          if (resColor.data.response.filtered.vibrant) {
            hasColorLight(resColor.data.response.filtered.vibrant)
          } else {
            hasColorLight(resColor.data.response.filtered.dark)
          }
          hasColorDark(resColor.data.response.filtered.dark)
          hasName(resCompany.data.response.name)
          if (resCompany.data.response.banner === null) {
            hasBanner(
              "https://www.burgershed41chester.co.uk/wp-content/uploads/2015/10/banner-placeholder.png"
            )
          } else {
            hasBanner(resCompany.data.response.banner)
          }
        } catch (err) {
          // CATCH IF ONE OF THE API HAS ERROR
          isErrorState(true)
          setErrorMsg("That email didn't work. Try again if you'd like.")
          setBtnWand(false)
          hasScreenShot("")
          hasLogo("")
          hasColorLight("")
          hasColorDark("")
          hasName("")
          hasBanner("")
        }
      } else {
        setBtnWand(true)
        isDisabled(true)
        // THE DOMAIN DOES EXIST IN GRAPHCMS? DO THIS
        axios({
          method: "POST",
          url:
            "https://api-us-east-1.graphcms.com/v2/ck9y8kwub07jx01z48nwmexjd/master",
          headers: {
            "Content-Type": "application/json",
            Authorization: GCMS_TOKEN,
          },
          data: {
            query: `
                        query checkBrands() {
                            companyCustomizations(stage: PUBLISHED) {
                                id
                                domain
                                screenShot
                                name
                                darkColor
                                lightColor
                                bannerUrl
                                logoUrl
                            }
                        }
                        `,
          },
        })
          .then(res => {
            setBtnWand(false)
            isErrorState(false)
            isSuccess(true)
            const resList = res.data.data.companyCustomizations

            var newArray = resList
              .filter(item => item.domain === domain)
              .map(item => ({
                domain: item.domain,
                screenShot: item.screenShot,
                name: item.name,
                darkColor: item.darkColor,
                lightColor: item.lightColor,
                bannerUrl: item.bannerUrl,
                logoUrl: item.logoUrl,
              }))

            newArray.forEach(item => {
              hasScreenShot(item.screenShot)
              hasName(item.domain)
              hasLogo(item.logoUrl)
              hasColorLight(item.lightColor)
              hasColorDark(item.darkColor)
              hasName(item.name)
              if (item.bannerUrl === "No available Banner") {
                hasBanner(
                  "https://www.burgershed41chester.co.uk/wp-content/uploads/2015/10/banner-placeholder.png"
                )
              } else {
                hasBanner(item.bannerUrl)
              }
            })
          })
          .catch(err => {
            console.log(err)
          })
      }
    } else {
      //INVALID EMAIL FORMAT
      isErrorState(true)
      setErrorMsg("That email didn't work. Try again if you'd like.")
      setBtnWand(false)
    }
  }

  function saveEmail() {
    axios({
      method: "post",
      url: `https://cors-anywhere.herokuapp.com/https://api.mailerlite.com/api/v2/groups/${MAILERLITE_GROUP_ID}/subscribers`,
      headers: {
        "content-type": "application/json",
        "X-MailerLite-ApiKey": `${MAILERLITE_API_KEY}`,
      },
      data: {
        email: email,
        type: "active",
      },
    })
      .then(res => {
        console.log("")
      })
      .catch(err => {
        console.log(err, "Error saving email")
      })
  }

  const handleSubmit = e => {
    e.preventDefault()
    saveEmail()
    doWork()
  }

  const bannerContent = {
    title: "Customize Billsby to suit your brand identity",
    description: [
      {
        text:
          "We think they're brilliant, and so will you. At Billsby, we dont make money until you do so our 100% UK AND US based support teams goals are directly aligned with yours- to help you build and grow your subscription business.",
      },
    ],
    buttons: [
      {
        isExternal: true,
        url: "https://ap.billsby.com/registration",
        buttonColor: "orange",
        buttonText: "Sign up today",
      },
      {
        scheduleButton: true,
        buttonColor: "black",
      },
    ],
    image: require("../../../images/customize.svg"),
    svg: true,
    imageName: "brand-identity",
    backgroundColor: "#F3F5FB",
    textColor: "#252422",
    imagePosition: "right",
    isBanner: true,
    brandIdentity: true,
  }

  const paymentMethodForm = {
    title: (
      <>
        Everything happens on <span>your</span> website
      </>
    ),
    description:
      "Checkout and account management all happens on your website - there's no redirecting customers to an external URL, you don't have to compromise your brand experience and customers won't get confused.",
    preview: (
      <>
        <div className="preview interactive-preview">
          <div
            className="preview-container"
            style={{
              backgroundColor: "#3a3938",
              backgroundImage: `url(${screenshot})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              backgroundSize: "cover",
            }}
          >
            {screenshot && (
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#252422e6",
                }}
              ></div>
            )}

            <div className="checkout-preview">
              <div className="checkout-preview__container">
                <div
                  className="checkout-preview__header"
                  style={{
                    color: `${colorLight ? colorLight : "#555555"}`,
                  }}
                >
                  <div className="checkout-preview__header__back-btn">
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                  <div className="checkout-preview__header__content">
                    Payment Details
                  </div>
                  <div className="checkout-preview__header__close-btn">
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>

                <div className="checkout-preview__content">
                  <div className="checkout-preview__content__top">
                    <div className="checkout-preview__form-group">
                      <label className="checkout-preview__form-group__label">
                        Card Holder Name
                      </label>
                      <input
                        className="checkout-preview__form-group__field"
                        placeholder="John Smith"
                        type="text"
                      />
                    </div>
                    <div className="checkout-preview__card-info">
                      <div className="checkout-preview__form-group">
                        <label className="checkout-preview__form-group__label">
                          Credit Card Number
                        </label>
                        <input
                          className="checkout-preview__form-group__field"
                          placeholder="1234 5678 1234 5678"
                          type="number"
                        />
                        <img
                          className="checkout-preview__form-group__field-image card-logos"
                          src={require("../../../images/payment-card-logos.svg")}
                          alt="payment-card-logos"
                        />
                      </div>

                      <div className="checkout-preview__form-group">
                        <label className="checkout-preview__form-group__label">
                          CVV
                        </label>
                        <input
                          className="checkout-preview__form-group__field"
                          placeholder="123"
                          type="number"
                        />
                      </div>

                      <div className="checkout-preview__form-group checkout-preview__form-group--expires">
                        <label className="checkout-preview__form-group__label">
                          Expires
                        </label>
                        <input
                          className="checkout-preview__form-group__field"
                          placeholder="MM"
                          type="number"
                        />
                        <input
                          className="checkout-preview__form-group__field"
                          placeholder="YYYY"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="checkout-preview__content__bottom">
                    <div className="checkout-preview__form-group checkout-preview__agreement">
                      <label>
                        <input
                          type="checkbox"
                          name="size"
                          id="small"
                          value="small"
                        />
                        <span
                          class="checkmark"
                          style={{
                            borderColor: `${
                              colorLight ? colorLight : "#5a5a5a"
                            }`,
                          }}
                        ></span>
                        <div className="checkout-preview__form-group__label">
                          I agree to the <u>Terms of Use</u> and{" "}
                          <u>Privacy Policy</u>
                        </div>
                      </label>
                    </div>
                    <button
                      className="checkout-preview__submit"
                      style={{
                        backgroundColor: `${
                          colorLight ? colorLight + 60 : "#555555"
                        }`,
                      }}
                    >
                      Pay $9.99 and subscribe
                    </button>
                    <div className="checkout-preview__links">
                      <u>How was this calculated?</u>
                    </div>
                  </div>
                </div>

                <div
                  className="checkout-preview__progress-bar"
                  style={{
                    backgroundColor: `${
                      colorLight ? colorLight + 60 : "#b9b9b9"
                    }`,
                  }}
                >
                  <div
                    className="checkout-preview__progress-bar__filler"
                    style={{
                      backgroundColor: `${colorLight ? colorLight : "#555555"}`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="checkout-preview__footer">
                <span className="checkout-preview__footer__text">
                  Subscription billing by
                </span>
                <a className="checkout-preview__footer__logo" href="#">
                  <img
                    src={require("../../../images/billsby-logo.svg")}
                    alt="Billsby"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  }

  const EmailLayout = {
    title: "Great branded emails build trust",
    description:
      "Checkout and account management all happens on your website - there's no redirecting customers to an external URL, you don't have to compromise your brand experience and customers won't get confused.",
    preview: (
      <>
        <div className="email-layout-holder">
          <div
            className="logo-holder"
            style={{
              backgroundColor: `${colorLight ? colorLight + 60 : "#E3E3E3"}`,
            }}
          >
            {logo ? (
              <img src={logo} alt="email-logo" />
            ) : (
              <h2>Payment Details</h2>
            )}
          </div>
          <div className="content">
            <h3>Thank you for your payment</h3>
            <p>Hi [Customer First Name],</p>
            <p>
              We've successfully renewed your subscription and received your
              payment. Thank you. We hope you're enjoying your subscription.
            </p>
            <p className="text-bold">Your renewal</p>
            <ul>
              <li>
                <span>Payment date</span>
                <span>[DATE]</span>
              </li>
              <li>
                <span>Invoiced amount</span>
                <span>[Invoice amount]</span>
              </li>
              <li>
                <span>Payment method</span>
                <span>[Card type] ending XXXX</span>
              </li>
            </ul>
            <p className="link">View your invoice</p>
            <p>Regards,</p>
            {name ? <p>{name}</p> : <p>Your Comapany</p>}
            <div className="banner">
              {banner ? (
                <img src={banner} alt="banner-logo" />
              ) : (
                <div className="placeholder"></div>
              )}
            </div>
            <p className="text-footer">
              If you've any questions about your subscription, don't hesitate to
              get in touch. Reply to this email or visit our{" "}
              <span>support website</span>. We're here to help.
            </p>
          </div>
          <div className="arrow-holder">
            <div className="arrow-item">
              <p>Your logo and colours</p>
              <img
                src={require("../../../images/arrow-blue.svg")}
                alt="arrow-blue"
              />
            </div>
            <div className="arrow-item">
              <p>Change copy to suit your brand</p>
              <img
                src={require("../../../images/arrow-orange.svg")}
                alt="arrow-orange"
              />
            </div>
            <div className="arrow-item">
              <p>Space to advertise what's new</p>
              <img
                src={require("../../../images/arrow-blue.svg")}
                alt="arrow-blue"
              />
            </div>
            <div className="arrow-item">
              <p>Direct customers to support</p>
              <img
                src={require("../../../images/arrow-orange-2.svg")}
                alt="arrow-orange"
              />
            </div>
          </div>
        </div>
      </>
    ),
  }

  const invoicePortal = {
    title: "Not just an invoice, it's your branded invoice portal",
    description:
      "Handle failed payments, manage customer support issues, promote new products and services and more in your branded invoice portal - so much better than just sending a PDF.",
    blackBrowser: true,
    preview: (
      <>
        <div className="preview interactive-preview">
          <div className="preview-container">
            <div className="invoice-holder">
              <div className="invoice-col">
                <div className="invoice-wrapper">
                  <div
                    className="logo-holder"
                    style={{
                      backgroundColor: `${
                        colorLight ? colorLight + 60 : "#E3E3E3"
                      }`,
                    }}
                  >
                    {logo ? <img src={logo} alt="logo" /> : <h2>Your Logo</h2>}
                    <p>INVOICE</p>
                  </div>
                  <div className="invoice-content">
                    <div className="invoice-header">
                      <div className="invoice-header-col">
                        <p>Billbot</p>
                        <p>123 Anytown</p>
                        <p>Anyville</p>
                        <p>United States</p>
                        <p>12345</p>
                      </div>
                      <div className="invoice-header-col">
                        <p>
                          Invoice Ref: <span>A153YZ6312A3-001</span>
                        </p>
                        <p>
                          Invoice Date: <span>May 22 2020</span>
                        </p>
                        <p>
                          Invoice Amount: <span>$17.12 (USD)</span>
                        </p>
                        <p>
                          Customer ID: <span>A153YZ6312A3</span>
                        </p>
                        <p className="btn-unpaid">UNPAID</p>
                      </div>
                    </div>
                    <div className="invoice-body">
                      <div className="invoice-body-row">
                        <h3>Pro Plan</h3>
                        <p>
                          Subscription ID: <span>B531DS6357</span>
                        </p>
                        <div className="table">
                          <div className="theader">
                            <div className="table-header">Description</div>
                            <div className="table-header">Units</div>
                            <div className="table-header">Unit Price</div>
                            <div className="table-header">Discounts</div>
                            <div className="table-header">Amount (USD)</div>
                          </div>
                          <div className="table-row">
                            <div className="table-col">
                              <div className="table-data">Description</div>
                              <div className="table-data">
                                Pro Plan - Monthly
                              </div>
                            </div>
                            <div className="table-col">
                              <div className="table-data">Units</div>
                              <div className="table-data">1</div>
                            </div>
                            <div className="table-col">
                              <div className="table-data">Unit Price</div>
                              <div className="table-data">$12.00</div>
                            </div>
                            <div className="table-col">
                              <div className="table-data">Discounts</div>
                              <div className="table-data">$2.00</div>
                            </div>
                            <div className="table-col">
                              <div className="table-data">Amount (USD)</div>
                              <div className="table-data">$10.00</div>
                            </div>
                          </div>
                        </div>
                        <p className="discount">Discounts</p>
                      </div>
                      <div className="invoice-body-row">
                        <h3>Basic Plan</h3>
                        <p>
                          Subscription ID: <span>E531DS65361</span>
                        </p>
                        <div className="table">
                          <div className="theader">
                            <div className="table-header">Description</div>
                            <div className="table-header">Units</div>
                            <div className="table-header">Unit Price</div>
                            <div className="table-header">Discounts</div>
                            <div className="table-header">Amount (USD)</div>
                          </div>
                          <div className="table-row">
                            <div className="table-col">
                              <div className="table-data">Description</div>
                              <div className="table-data">
                                Basic Plan - Monthly
                              </div>
                            </div>
                            <div className="table-col">
                              <div className="table-data">Units</div>
                              <div className="table-data">1</div>
                            </div>
                            <div className="table-col">
                              <div className="table-data">Unit Price</div>
                              <div className="table-data">$6.00</div>
                            </div>
                            <div className="table-col">
                              <div className="table-data">Discounts</div>
                              <div className="table-data">-</div>
                            </div>
                            <div className="table-col">
                              <div className="table-data">Amount (USD)</div>
                              <div className="table-data">$6.00</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="invoice-body-row totals">
                        <h3>Totals</h3>
                        <ul>
                          <li>
                            <p>Subtotal</p>
                            <p>$16.00</p>
                          </li>
                          <li>
                            <p>
                              Tax <span>(Sales tax @ 7%)</span>
                            </p>
                            <p>$1.12</p>
                          </li>
                          <li>
                            <p>Total</p>
                            <p>$17.12</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="invoice-footer"
                      style={{
                        backgroundColor: `${
                          colorLight ? colorLight + 60 : "#E3E3E3"
                        }`,
                      }}
                    >
                      <p>
                        Comapany Name, 123 Anytown, Anyville, United States,
                        12345 +1 (888) 123-4567 | payments@companyname.com
                      </p>
                      <p>
                        Subscription billing by <span>Billsby</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="invoice-col">
                <div className="invoice-wrapper">
                  <div
                    className="logo-holder"
                    style={{
                      backgroundColor: `${
                        colorLight ? colorLight + 60 : "#E3E3E3"
                      }`,
                    }}
                  >
                    {logo ? <img src={logo} alt="logo" /> : <h2>Your Logo</h2>}
                    <p className="icon-pdf-file">
                      <FontAwesomeIcon icon={faFilePdf} />
                    </p>
                  </div>
                  <div className="right-content">
                    <div className="card-item red">
                      <p>Your Invoice of $17.12 is unpaid</p>
                      <p>
                        Help us to resolve this payment issue and keep your
                        subscriptions current
                      </p>
                    </div>
                    <div className="card-item">
                      <p>Charge your card-on-file</p>
                      <p>
                        We can reattempt the payment using your card-on-file, a
                        Visa card ending 1234.
                      </p>
                    </div>
                    <div
                      className="card-btn"
                      style={{
                        backgroundColor: `${colorDark ? colorDark : "#505050"}`,
                      }}
                    >
                      <p>
                        charge $17.12 to visa ending 1234{" "}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </p>
                    </div>
                    <div className="card-item">
                      <p>Replace your payment card</p>
                      <p>
                        If this card doesn't work anymore, we can replace your
                        payment card with a new one.
                      </p>
                    </div>
                    <div
                      className="card-btn"
                      style={{
                        backgroundColor: `${colorDark ? colorDark : "#505050"}`,
                      }}
                    >
                      <p>
                        replace payment card{" "}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </p>
                    </div>
                    <div className="card-item">
                      <p>Talk to our team</p>
                      <p>
                        If you can't make a payment right now, our team would be
                        happy to talk you through your options and help you
                        avoid account suspension.
                      </p>
                      <p>
                        You can call us on 888-123-4567, email
                        payments@comapnyname.cpm or visit our support portal.
                      </p>
                    </div>
                    <div
                      className="card-btn"
                      style={{
                        backgroundColor: `${colorDark ? colorDark : "#505050"}`,
                      }}
                    >
                      <p>
                        visit our support portal{" "}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </p>
                    </div>
                  </div>
                  <div className="right-content-footer">
                    <p>
                      Subscription billing by <span>Billsby</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  }

  return (
    <Layout className="brand-identity">
      <SEO
        title="Customizable subscription billing software | Billsby"
        description="Customizable subscription billing software to suit your brand identity. Powerful, customizable, and easy-to-integrate recurring billing solution. | Billsby"
        url="https://www.billsby.com/product/creating/brand-identity"
      />

      <TwoColumn content={bannerContent} />
      {!success ? (
        <WorkEmailAddress
          className="theme-orange"
          email={email}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          disabled={disabled}
          iconClass="wand-icon"
          errorState={errorState}
          errorMsg={errorMsg}
          btnWand={btnWand}
          headingText="Enter your work email address to see how Billsby will look in your
          brand colours immediately."
          subHeadingText={
            <>
              We'll never share your details. We might follow up about Billsby,
              but you can always unsubscribe. It's in our{" "}
              <Link to="#">privacy policy.</Link>
            </>
          }
        />
      ) : (
        <WorkEmailAddress
          className="theme-black"
          headingText={<>Here's how {name} could look in Billsby</>}
          subHeadingText="Pick your own colors and upload your own logo with a free trial account"
          success={success}
        />
      )}
      <Preview content={paymentMethodForm} className="payment-method-section" />
      <AccountManagement
        logo={logo}
        colorLight={colorLight}
        colorDark={colorDark}
      />
      {!success && (
        <WorkEmailAddress
          className="theme-blue"
          email={email}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          disabled={disabled}
          iconClass="wand-icon"
          errorState={errorState}
          errorMsg={errorMsg}
          btnWand={btnWand}
          headingText="You know what'd make this page more interesting? Your brand. Let us show you how Billsby will look for you."
          subHeadingText={
            <>
              We'll never share your details. We might follow up about Billsby,
              but you can always unsubscribe. It's in our{" "}
              <Link to="#">privacy policy.</Link>
            </>
          }
        />
      )}
      <Preview content={EmailLayout} className="email-layout-section" />
      <div className="text-two-column-section">
        <div className="container">
          <h2>It's your brand, so you should always feel in control.</h2>
          <div className="text-row">
            <div className="text-col">
              <ul>
                <li>
                  <p>Create your own checkout</p>
                  <p>
                    Take full control of the checkout experience and design your
                    own pages using our card tokenizer and API.
                  </p>
                </li>
                <li>
                  <p>Get on brand statements</p>
                  <p>
                    Customize your company name on card statements - so
                    customers can immediately recognise each charge.
                  </p>
                </li>
              </ul>
            </div>
            <div className="text-col">
              <ul>
                <li>
                  <p>Send your own emails</p>
                  <p>
                    Turn off our emails and take full control generating emails
                    for every billing scenario triggered by our web hooks.
                  </p>
                </li>
                <li>
                  <p>Pick and choose</p>
                  <p>
                    Use a mix of our pre-built components and onces you design
                    yourself, so you can start easy and grow into something more
                    advanced.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {!success && (
        <WorkEmailAddress
          className="theme-orange"
          email={email}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          disabled={disabled}
          iconClass="wand-icon"
          errorState={errorState}
          errorMsg={errorMsg}
          btnWand={btnWand}
          headingText="Enter your work email address to see how Billsby will look in your
        brand colours immediately."
          subHeadingText={
            <>
              We'll never share your details. We might follow up about Billsby,
              but you can always unsubscribe. It's in our{" "}
              <Link to="#">privacy policy.</Link>
            </>
          }
        />
      )}
      <Preview content={invoicePortal} className="invoice-portal-section" />
    </Layout>
  )
}

export default BrandIdentity
