import React from "react"
import MagicWand from "../assets/magic-wand.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import classNames from "classnames"

const WorkEmailAddress = ({
  className,
  email,
  handleChange,
  handleSubmit,
  headingText,
  subHeadingText,
  disabled,
  iconClass,
  errorState,
  errorMsg,
  btnWand,
  success,
}) => {
  return (
    <div
      className={`email-form-section${className ? ` ${className}` : ""}${
        errorState ? " error-email" : ""
      }`}
    >
      <div className="container">
        <div className="email-form-wrapper">
          <div className="text-holder">
            <p className="heading">{headingText}</p>
            <p className="sub-heading">{subHeadingText}</p>
          </div>
          {!success ? (
            <form className={classNames("form-holder", { error: errorState })}>
              <input
                type="email"
                name="emailAdd"
                placeholder="Enter your work email address"
                value={email}
                onChange={handleChange}
              />
              <button type="submit" onClick={handleSubmit} disabled={disabled}>
                {btnWand ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <MagicWand className={iconClass} />
                )}
              </button>

              {errorState && (
                <div className="error-msg">
                  <p>{errorMsg}</p>
                </div>
              )}
            </form>
          ) : (
            <div className="btn-holder">
              <Link to="https://app.billsby.com/registration">
                Sign up today
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default WorkEmailAddress
