import React, { useEffect } from "react"

const AccountManagement = ({ colorLight, colorDark, logo }) => {
  const accountIcon = () => {
    const icons = document.querySelectorAll(".account-management-icon > path")

    for (let i = 0; i < icons.length; i++) {
      icons[i].style.fill = colorLight + 60
    }
  }

  useEffect(() => {
    accountIcon()
    // eslint-disable-next-line
  }, [colorLight])
  return (
    <div className="account-management-section">
      <div className="container">
        <div className="account-management-wrapper">
          <div className="account-management-col">
            <h2 className="title">
              Done-for-you checkout and account management in your brand
              identity.
            </h2>
            <ul className="description-holder">
              <li>
                <p>Get the best of both worlds</p>
                <p>
                  Get both blazing fast implementation and the branded
                  experience your customer deserves, served from your domain.
                </p>
              </li>
              <li>
                <p>Update brand elements instantly</p>
                <p>
                  And see your changes reflected straight away across all of
                  your Billsby modals, emails, invoices and more.
                </p>
              </li>
              <li>
                <p>If you can do it in Billsby, you can do it with the API</p>
                <p>
                  When you're ready, go even further and make some elements of
                  the journey completely customized with our API. It's not an
                  either-or choice - you can start with our simple drop-in code,
                  and then customize more of the experience as you got at your
                  own pace.
                </p>
              </li>
              <li>
                <p>Not just skin deep</p>
                <p>
                  Our customization goes far beyond colours. Want to call your
                  plans "bundles"? Sure thing. Need people to choose how many
                  'seats' they want rather than units? We've got you covered.
                  The terminology in your checkout and account management
                  journey can match what you use everywhere else.
                </p>
              </li>
            </ul>
          </div>
          <div className="account-management-col">
            <div className="account-preview">
              <div className="account-preview__container">
                <div
                  className="account-preview__header"
                  style={{
                    color: `${colorLight ? colorLight : "#555555"}`,
                  }}
                >
                  <div className="account-preview__header__content">
                    Hello John Smith!
                  </div>
                  {/* <div className="account-preview__header__close-btn">
                    {colorLight && (
                      <FontAwesomeIcon icon={faTimes} />
                    )}
                  </div> */}
                </div>
                <div
                  className="account-preview__content"
                  style={{
                    color: `${colorLight ? colorLight : "#555555"}`,
                  }}
                >
                  <div className="account-preview__panel">
                    View and change subscription
                  </div>
                  <div className="account-preview__panel">Personal details</div>
                  <div className="account-preview__panel">Address details</div>
                  <div className="account-preview__panel">
                    Update payment details
                  </div>
                  <div className="account-preview__panel">Manage add-ons</div>
                  <div className="account-preview__panel">
                    View billing history
                  </div>
                  <div className="account-preview__panel">
                    Cancel subscription
                  </div>
                </div>
                <div className="account-preview__gradient"></div>
              </div>
              {/* <div className="account-preview__footer">
                <span className="account-preview__footer__text">Subscription billing by</span>
                <a className="account-preview__footer__logo" href="#"><img src={require("../images/billsby-logo.svg")} alt="Billsby" /></a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountManagement
